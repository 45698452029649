// Fonts

// Colors

$orderNew: #ECAF35;
$orderConfirmed: #27B683;
$orderProduction: #00928C;
$orderSending: #E19836;
$orderSent: #A56500;
$orderPrepared: #BC736C;
$orderPickedup: #885963;
$orderCompleted: #63C14B;
$orderCancelled: #777;
$orderFailed: #B3353B;

$orderOpen: #63C14B;
$orderClosed: #777;

// Sizes
