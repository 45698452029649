@import "../_variables";

button, .btn {
  .la, .fa {
    vertical-align: middle;
  }

  &:not(.btn-icon) {
    .la, .fa {
      margin: 0 5px 0 -5px;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.btn-rounded {
    border-radius: 25%;
  }
}

picture {
  display: inline-block;

  &.img-fluid {
    max-width: 100%;
    height: auto;
  }

  &.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    object-fit: inherit;
    width: 100%;
    height: 100%;
  }
}

label {
  font-weight: 500;
}

span.color-preview {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 2px;
  vertical-align: text-top;
}

.input-group {
  .input-group-append, .input-group-prepend {
    input.form-control[type="color"] {
      width: 37px;
      height: auto;
    }

    .input-group-text {
      border-radius: 0;
    }

    .vue-treeselect__control {
      width: auto;
      height: 38px;
      background-color: #f7f8fa;
      border: 1px solid #e2e5ec;

      .vue-treeselect__single-value {
        color: #a7abc3;
      }

      .vue-treeselect__input-container {
        width: min-content;
        padding: 0 15px;
      }
    }
  }
}

.kt-switch {
  padding: 2px 0;
}

.kt-checkbox > input:disabled ~ span {
  cursor: not-allowed;
  pointer-events: auto;
}

.kt-checkbox {
  line-height: 18px;
  padding-left: 23px;
  margin-bottom: 0;

  input[type=checkbox] {
    left: 0;
    width: 18px;
    height: 18px;
  }

  > span {
    top: 0;
  }

  &.kt-checkbox--no-label {
    width: 18px;
    height: 18px;
    padding-left: 0;
  }
}

.clickable {
  cursor: pointer;
}

.handle {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.hint-wrapper {
  position: relative;
  display: inline-flex;
  width: 25px;
  height: 25px;
  margin: 2px 0 2px 5px;
  vertical-align: middle;

  button.btn.btn-icon {
    position: absolute;
    right: 0;
    width: 25px !important;
    height: 25px !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 25%;

    i {
      font-size: 1rem !important;
      line-height: 1;
    }
  }
}

label ~ .hint-wrapper {
  bottom: 2px;
}

.form-group {
  .label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hint-wrapper {
      width: 20px;
      height: 20px;

      button.btn.btn-icon {
        width: 20px !important;
        height: 20px !important;

        i {
          font-size: 0.9rem !important;
        }
      }
    }
  }

  &.required {
    > .label-wrapper label:after, > label:after {
      content: '*';
      color: #dc3545;
    }
  }

  &.is-invalid {
    .invalid-feedback {
      display: block;
    }
  }
}

.dynamic-form {
  .form-group.required {
    div[type="SelectInput"] {
      > .label-wrapper label:after, > label:after {
        content: '*';
        color: #dc3545;
      }
    }
  }
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
  background-color: #3bb9cc;

  span {
    font-weight: 500;
  }
}

.kt-nav .kt-nav__item > .kt-nav__link.active {
  background-color: #f7f8fa;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  .kt-nav__link-text {
    color: #2c77f4;
  }
}

.kt-widget17 .kt-widget17__stats {
  margin-bottom: 2rem;
}

.media-input {
  .media-preview {
    text-align: center;
    margin-bottom: 5px;

    img {
      height: 100px;
      object-fit: contain;
    }
  }
}

.vue-treeselect {
  .vue-treeselect__control {
    display: flex;
    align-items: center;
    border-radius: 0;

    .vue-treeselect__value-container {
      display: block;
      line-height: 20px;
      height: 20px;
      overflow: hidden;

      .vue-treeselect__placeholder, .vue-treeselect__single-value, .vue-treeselect__multi-value-item {
        line-height: 20px;
      }

      .vue-treeselect__multi-value-item {
        margin-top: -8px;
      }
    }

    .vue-treeselect__control-arrow-container {
      display: inline-block;
    }
  }

  .vue-treeselect__placeholder, .vue-treeselect__single-value {
    font-weight: 400;
    padding-left: 8px;
    padding-right: 8px;
    color: #495057;
  }

  &.is-invalid {
    .vue-treeselect__control, .vue-treeselect__control:hover {
      border-color: #fd397a !important;
    }

    ~ .invalid-feedback {
      display: block;
    }
  }

  &.form-control {
    padding: 0;
    border: 0;

    .vue-treeselect__control {
      height: calc(1.5em + 1.3rem + 2px);
    }
  }
}

.vue-table .is-money {
  font-family: sans-serif !important;
}

.kt-badge.kt-badge--inline {
  height: 0;
  width: auto;
  padding: .85rem .85rem;
}

.btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon {
  height: 2rem;
  width: 2rem;
}

body:not(.is-fullscreen) .iziToast-wrapper.iziToast-wrapper-topRight {
  margin-top: 60px;
}

.item-rolled {
  display: none;

  &.showed {
    display: block;
  }
}

.v-stepper {
  .kt-portlet {
    margin-bottom: 0;

    .kt-portlet__head {
      padding: 10px 0;
    }

    .kt-portlet__body {
      padding: 10px;
    }
  }

  .vue-treeselect {
    min-width: 170px;
  }

  .v-card.v-sheet.theme--light {
    background: #f9f9f9;
    padding: 0 20px;
  }

  .v-stepper__step {
    .v-stepper__step__step.primary {
      background: #ddd !important;
    }
  }

  .v-stepper__step.v-stepper__step--active {
    .v-stepper__step__step.primary {
      background: #00c5dc !important;
      font-weight: 600;
    }
  }

  .v-stepper__content {
    h4 {
      font-size: 1.2em;
      text-transform: uppercase;
      font-weight: 400;
      margin: 20px 0 15px;
    }
    h5 {
      font-size: 1.1em;
      font-weight: 400;
    }
    padding: 20px;
  }

  .vue-treeselect__control {
    border-radius: 0;
  }

  .vue-treeselect__menu {
    max-height: 200px !important;
    border-radius: 0;
  }

  &:not(.v-stepper--vertical) {
    .v-stepper__header {
      flex-wrap: nowrap;

      .v-stepper__label {
        text-align: center;
        display: block;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .v-stepper:not(.v-stepper--vertical) {
    .v-stepper__header {
      .v-stepper__label {
        display: none;
      }
    }
  }
}

.v-picker {
  z-index: 100;
  .v-picker__title.primary {
    background: #5578eb !important;
  }

  .v-date-picker-header {
    button {
      background-color: transparent !important;
      border: none !important;
    }
  }

  button.v-btn {
    background: transparent;
    border: none;
  }

  button.v-btn:not(.v-btn--outlined).accent {
    background-color: #5578eb !important;
    color: #fff !important;
    border: none !important;
  }
}

.product-wizard {
  .slug {
    margin-top: 48px;
  }

  .add-product-image {
    height: 3rem;
    width: 3rem;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    color: #2c77f4;
    border: 1px solid #2c77f4;
    background: transparent;
    outline: none !important;
    vertical-align: middle;

    &:hover {
      background: #2c77f4;

      i {
        color: #fff;
      }
    }
  }

  .plus {
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  .product-attributes {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .btn-outline-accent {
    background-color: white;
  }

  .btn-outline-accent.focus, .btn-outline-accent:focus, .btn-outline-accent:hover {
    background-color: #00c5dc;
  }

  .images {
    .image {
      position: relative;

      .remove-btn {
        position: absolute;
        z-index: 100;
        top: 5px;
        right: 5px;
      }
    }

    .frontX > div {
      background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Vrstva_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'\
	 width='127px' height='126px' viewBox='0 0 127 126' enable-background='new 0 0 127 126' xml:space='preserve'>\
<g>\
	<path fill='#C9C9C9' d='M19.089,59.139h-1.931v7.723h1.931V59.139z M19.089,74.586h-1.931v7.724h1.931V74.586z M67.362,16.658\
		h-7.725v1.931h7.725V16.658z M19.089,43.691h-1.931v7.724h1.931V43.691z M107.911,107.411h-1.932v1.931h3.862v-3.862h-1.931\
		V107.411z M19.089,105.479h-1.931v3.862h3.861v-1.931h-1.931V105.479z M51.914,16.658H44.19v1.931h7.724V16.658z M67.362,107.411\
		h-7.725v1.931h7.725V107.411z M17.158,20.52h1.931v-1.931h1.931v-1.931h-3.861V20.52z M51.914,107.411H44.19v1.931h7.724V107.411z\
		 M19.089,90.032h-1.931v7.725h1.931V90.032z M107.911,35.967h1.931v-7.724h-1.931V35.967z M107.911,97.757h1.931v-7.725h-1.931\
		V97.757z M98.257,107.411h-7.725v1.931h7.725V107.411z M98.257,16.658h-7.725v1.931h7.725V16.658z M36.467,16.658h-7.723v1.931\
		h7.723V16.658z M36.467,107.411h-7.723v1.931h7.723V107.411z M107.911,51.415h1.931v-7.724h-1.931V51.415z M19.089,28.243h-1.931\
		v7.724h1.931V28.243z M107.911,82.31h1.931v-7.724h-1.931V82.31z M105.979,18.589h1.932v1.931h1.931v-3.861h-3.862V18.589z\
		 M107.911,66.861h1.931v-7.723h-1.931V66.861z M82.81,107.411h-7.724v1.931h7.724V107.411z M82.81,16.658h-7.724v1.931h7.724\
		V16.658z'/>\
	<path fill='#C9C9C9' d='M63.5,90.998c13.517,0,24.137-5.513,24.137-12.551c0.001-0.161-0.039-0.321-0.116-0.464L64.35,35.504\
		c-0.253-0.47-0.839-0.645-1.308-0.392c-0.166,0.09-0.303,0.226-0.392,0.392L39.479,77.983c-0.077,0.143-0.117,0.303-0.116,0.464\
		C39.363,85.485,49.983,90.998,63.5,90.998z M63.5,37.946l22.205,40.732c-0.279,5.648-10.33,10.389-22.205,10.389\
		s-21.926-4.73-22.206-10.389L63.5,37.946z'/>\
</g>\
</svg>") no-repeat;
    }

    .sideX > div {
      background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Vrstva_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'\
	 width='127px' height='126px' viewBox='0 0 127 126' enable-background='new 0 0 127 126' xml:space='preserve'>\
<g>\
	<rect x='90.533' y='16.658' fill='#C7C7C7' width='7.723' height='1.931'/>\
	<rect x='90.533' y='107.411' fill='#C7C7C7' width='7.723' height='1.931'/>\
	<rect x='107.911' y='28.243' fill='#C7C7C7' width='1.931' height='7.724'/>\
	<rect x='107.911' y='43.691' fill='#C7C7C7' width='1.931' height='7.724'/>\
	<rect x='107.911' y='59.139' fill='#C7C7C7' width='1.931' height='7.723'/>\
	<polygon fill='#C7C7C7' points='105.98,18.589 107.911,18.589 107.911,20.52 109.842,20.52 109.842,16.658 105.98,16.658 	'/>\
	<rect x='107.911' y='90.033' fill='#C7C7C7' width='1.931' height='7.724'/>\
	<rect x='107.911' y='74.586' fill='#C7C7C7' width='1.931' height='7.724'/>\
	<polygon fill='#C7C7C7' points='107.911,107.411 105.98,107.411 105.98,109.342 109.842,109.342 109.842,105.479 107.911,105.479\
      '/>\
	<polygon fill='#C7C7C7' points='17.158,20.52 19.089,20.52 19.089,18.589 21.02,18.589 21.02,16.658 17.158,16.658 	'/>\
	<rect x='44.19' y='107.411' fill='#C7C7C7' width='7.724' height='1.931'/>\
	<rect x='17.158' y='28.243' fill='#C7C7C7' width='1.931' height='7.724'/>\
	<rect x='17.158' y='43.691' fill='#C7C7C7' width='1.931' height='7.724'/>\
	<rect x='59.638' y='107.411' fill='#C7C7C7' width='7.724' height='1.931'/>\
	<rect x='17.158' y='74.586' fill='#C7C7C7' width='1.931' height='7.724'/>\
	<rect x='17.158' y='90.033' fill='#C7C7C7' width='1.931' height='7.724'/>\
	<rect x='17.158' y='59.139' fill='#C7C7C7' width='1.931' height='7.723'/>\
	<rect x='28.743' y='107.411' fill='#C7C7C7' width='7.724' height='1.931'/>\
	<rect x='75.085' y='107.411' fill='#C7C7C7' width='7.724' height='1.931'/>\
	<rect x='28.743' y='16.658' fill='#C7C7C7' width='7.724' height='1.931'/>\
	<polygon fill='#C7C7C7' points='19.089,105.479 17.158,105.479 17.158,109.342 21.02,109.342 21.02,107.411 19.089,107.411 	'/>\
	<rect x='59.638' y='16.658' fill='#C7C7C7' width='7.724' height='1.931'/>\
	<rect x='75.085' y='16.658' fill='#C7C7C7' width='7.724' height='1.931'/>\
	<rect x='44.19' y='16.658' fill='#C7C7C7' width='7.724' height='1.931'/>\
	<path fill='#C7C7C7' d='M85.705,80.378H37.433c-0.533,0-0.965-0.434-0.964-0.967c0-0.185,0.054-0.365,0.152-0.52l26.067-40.55\
      c0.087-0.137,0.206-0.249,0.348-0.328c6.15-3.37,16.316,3.331,22.669,14.926c2.891,5.045,4.584,10.687,4.953,16.49\
      c0.164,5.3-1.43,9.143-4.49,10.822C86.027,80.333,85.867,80.376,85.705,80.378z M39.199,78.448h46.245\
      c4.905-2.994,4.277-14.135-1.438-24.572c-5.715-10.437-14.713-16.799-19.83-14.279L39.199,78.448z'/>\
</g>\
</svg>") no-repeat;
    }

    .detailX > div {
      background: url("data:image/svg+xml;utf8,<svg version='1.1' id='Vrstva_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'\
	 width='127px' height='126px' viewBox='0 0 127 126' enable-background='new 0 0 127 126' xml:space='preserve'>\
<g>\
	<path fill='#C9C9C9' d='M100.854,80.639L83.475,64.227c-0.374-0.362-0.967-0.362-1.342,0l-1.738,1.757l-4.527-4.528\
		c7.186-7.938,6.576-20.196-1.361-27.383c-7.938-7.185-20.197-6.576-27.383,1.361c-7.186,7.938-6.576,20.198,1.361,27.383\
		c7.386,6.686,18.637,6.686,26.021,0l4.527,4.528l-1.728,1.728c-0.381,0.374-0.388,0.984-0.015,1.366\
		c0.004,0.005,0.01,0.01,0.015,0.016l17.378,16.412c0.375,0.361,0.969,0.361,1.342,0l4.828-4.827\
		c0.381-0.373,0.388-0.984,0.015-1.365c-0.005-0.006-0.009-0.011-0.015-0.016V80.639z M61.568,65.896\
		c-9.598,0-17.378-7.781-17.378-17.378s7.78-17.378,17.378-17.378s17.379,7.78,17.379,17.378S71.166,65.896,61.568,65.896z\
		 M95.359,84.829L79.362,69.758l3.446-3.485l15.998,15.071L95.359,84.829z'/>\
	<path fill='#C9C9C9' d='M68.723,68.513c-0.617,0.223-1.246,0.415-1.883,0.579l9.211,17.166c-0.271,3.64-6.759,6.671-14.482,6.671\
		c-7.723,0-14.211-3.031-14.481-6.671l9.2-17.166c-0.637-0.164-1.264-0.356-1.883-0.579l-9.133,17.06\
		c-0.074,0.14-0.115,0.296-0.115,0.454c0,4.972,7.211,8.834,16.412,8.834s16.413-3.862,16.413-8.824\
		c-0.001-0.159-0.04-0.314-0.116-0.454L68.723,68.513z M61.762,39.829L61.762,39.829c-0.346-0.002-0.666,0.183-0.84,0.482\
		L50.205,58.944c0.447,0.494,0.924,0.958,1.43,1.392l10.107-17.61l9.79,17.581c0.506-0.433,0.983-0.897,1.429-1.39L62.602,40.322\
		C62.432,40.018,62.11,39.83,61.762,39.829z'/>\
	<circle fill='#C9C9C9' cx='59.639' cy='60.104' r='0.965'/>\
	<circle fill='#C9C9C9' cx='63.5' cy='60.104' r='0.965'/>\
	<circle fill='#C9C9C9' cx='67.361' cy='60.104' r='0.965'/>\
	<circle fill='#C9C9C9' cx='55.775' cy='60.104' r='0.965'/>\
	<circle fill='#C9C9C9' cx='57.707' cy='56.242' r='0.965'/>\
	<rect x='17.158' y='74.586' fill='#C9C9C9' width='1.931' height='7.724'/>\
	<rect x='44.19' y='107.411' fill='#C9C9C9' width='7.724' height='1.931'/>\
	<rect x='17.158' y='90.033' fill='#C9C9C9' width='1.931' height='7.724'/>\
	<rect x='28.743' y='107.411' fill='#C9C9C9' width='7.724' height='1.931'/>\
	<rect x='59.639' y='107.411' fill='#C9C9C9' width='7.723' height='1.931'/>\
	<polygon fill='#C9C9C9' points='17.158,20.52 19.089,20.52 19.089,18.589 21.02,18.589 21.02,16.658 17.158,16.658 	'/>\
	<rect x='28.743' y='16.658' fill='#C9C9C9' width='7.724' height='1.931'/>\
	<rect x='59.639' y='16.658' fill='#C9C9C9' width='7.723' height='1.931'/>\
	<rect x='107.911' y='90.033' fill='#C9C9C9' width='1.931' height='7.724'/>\
	<rect x='107.911' y='74.586' fill='#C9C9C9' width='1.931' height='7.724'/>\
	<polygon fill='#C9C9C9' points='107.911,107.411 105.979,107.411 105.979,109.342 109.842,109.342 109.842,105.479\
		107.911,105.479 	'/>\
	<rect x='107.911' y='59.139' fill='#C9C9C9' width='1.931' height='7.723'/>\
	<polygon fill='#C9C9C9' points='105.979,18.589 107.911,18.589 107.911,20.52 109.842,20.52 109.842,16.658 105.979,16.658 	'/>\
	<rect x='107.911' y='43.691' fill='#C9C9C9' width='1.931' height='7.724'/>\
	<rect x='75.086' y='16.658' fill='#C9C9C9' width='7.723' height='1.931'/>\
	<rect x='107.911' y='28.243' fill='#C9C9C9' width='1.931' height='7.724'/>\
	<rect x='17.158' y='28.243' fill='#C9C9C9' width='1.931' height='7.724'/>\
	<polygon fill='#C9C9C9' points='19.089,105.479 17.158,105.479 17.158,109.342 21.02,109.342 21.02,107.411 19.089,107.411 	'/>\
	<rect x='90.532' y='16.658' fill='#C9C9C9' width='7.724' height='1.931'/>\
	<rect x='44.19' y='16.658' fill='#C9C9C9' width='7.724' height='1.931'/>\
	<rect x='90.532' y='107.411' fill='#C9C9C9' width='7.724' height='1.931'/>\
	<rect x='17.158' y='59.139' fill='#C9C9C9' width='1.931' height='7.723'/>\
	<rect x='75.086' y='107.411' fill='#C9C9C9' width='7.723' height='1.931'/>\
	<rect x='17.158' y='43.691' fill='#C9C9C9' width='1.931' height='7.724'/>\
	<circle fill='#C9C9C9' cx='61.568' cy='56.242' r='0.965'/>\
	<circle fill='#C9C9C9' cx='65.431' cy='56.242' r='0.965'/>\
	<circle fill='#C9C9C9' cx='59.639' cy='52.38' r='0.965'/>\
	<circle fill='#C9C9C9' cx='61.568' cy='48.518' r='0.965'/>\
	<circle fill='#C9C9C9' cx='63.5' cy='52.38' r='0.965'/>\
</g>\
</svg>") no-repeat;
    }
  }
}

.product-wizard .image, .drag-helper.image {
  position: relative;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  padding-top: 10px;
  padding-bottom: 10px;

  &:first-child {
    background: #daeff1;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    button {
      margin: 0 !important;
    }
  }

  > div {
    display: block;
    flex-grow: 2;

    a {
      display: block;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.product-wizard .product-attribute-row, .drag-helper.product-attribute-row {
  margin-top: 0.5rem;

  > .row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #dee2e6;
    background-color: #fff;

    .col {
      flex-grow: 1;
    }
  }

  .values {
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #f8f9fa;

    .row {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      background-color: #f8f9fa;
      border-bottom: 1px solid #dee2e6;

      .col {
        flex-grow: 1;
      }
    }
  }
}

.product-wizard .product-value-row, .drag-helper.product-value-row {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;

  .col {
    flex-grow: 1;
  }
}

.status {
  color: #fff;
  padding: 1px 6px 2px;
  border-radius: .25rem;

  &.status--new {
    background-color: $orderNew;
  }

  &.status--created {
    background-color: $orderNew;
  }

  &.status--confirmed {
    background-color: $orderConfirmed;
  }

  &.status--production {
    background-color: $orderProduction;
  }

  &.status--sending {
    background-color: $orderSending;
  }

  &.status--sent {
    background-color: $orderSent;
  }

  &.status--prepared {
    background-color: $orderPrepared;
  }

  &.status--pickedup {
    background-color: $orderPickedup;
  }

  &.status--completed {
    background-color: $orderCompleted;
  }

  &.status--cancelled {
    background-color: $orderCancelled;
  }

  &.status--failed {
    background-color: $orderFailed;
  }

  &.status--open {
    background-color: $orderOpen;
  }

  &.status--closed {
    background-color: $orderClosed;
  }
}

.jsPanel {
  .modal-content {
    .modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .modal-body:not(:last-child) {
      max-height: calc(100% - 70px);
    }

    .modal-footer {
      display: flex;
    }
  }
}

@media screen and (max-width: 768px) {
  .jsPanel {
    max-width: 100%;
    max-height: 100%;
  }

  .media-manager-modal {
    .fm-navbar {
      > .row {
        margin-right: 0;
        flex-direction: column;

        .col:first-child {
          flex-direction: column;
        }
      }

      .btn-group {
        display: flex;
        margin-bottom: 5px;
      }
    }

    .fm-tree {
      display: none;
    }
  }
}

.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user img {
  width: auto;
}

/* spinner */
.kt-spinner.overlay:before {
  z-index: 20;
  position: absolute;
  left: 50%;
}

.kt-portlet.overlay {
  &:after {
    content: "";
    z-index: 10;
    background: #fff;
    opacity: 0.35;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.kt-userpic span {
  display: inline-flex;
}

.order {
  .order-part {
    .attribute {
      b {
        font-weight: 500;
      }
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtered {
  list-style: none;
  li {
    font-size: 0.8rem;
  }
}

.kt-footer__menu-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0 1.25rem 0 0;
  font-size: 1rem;
  font-weight: 400;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  color: #a7abc3;
}

@media (max-width: 768px) {
  .kt-footer .kt-footer__menu-link {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}

.kt-content {
  img {
    max-width: 100%;
  }
}
